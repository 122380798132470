.conversations-tab {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  .conversations-list {
    // padding-bottom: 40px;
    // height: 100%;
    overflow-y: auto;
    flex-grow: 1;
    .conversation-card {
      &:nth-child(1) {
        .conversation-card__name {
          border: none;
        }
      }
    }
  }
  .ant-btn {
    // position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto -1px -1px -1px;
    padding: 16px 0;
    height: 63px;
    z-index: 100;
  }
}
