.sign-up {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  background-color: #fff;
  &__form {
    padding: 24px 24px 36px;
    max-width: 420px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 3px;
    &__header {
      margin-bottom: 24px;
      > h2 {
        margin-bottom: 8px;
        font-size: 1.625rem;
        font-weight: 600;
      }
      > p {
        padding-right: 16px;
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
    > form {
      > input {
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 8px;
      }
      > button {
        height: 44px;
        padding: 0 16px;
        font-size: 0.875rem;
      }
      .sign-up-btn {
        margin-top: 36px;
        margin-bottom: 12px;
      }
      .sign-in-with-email {
        margin-top: 24px;
        font-size: 0.875rem;
      }
    }
  }
}
