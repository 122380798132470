.create-conversation {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: transform 0.3s cubic-bezier(0.25, 0.75, 0.7, 1);
  background-color: #fff;
  > header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 108px;
    padding: 0 20px 0 23px;
    background-color: #fff;
    color: #555;
    > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 59px;
      .ant-btn {
        color: #555;
        margin-right: 16px;
      }
      > div {
        flex-grow: 1;
        font-weight: 500;
        font-size: 19px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .search-contacts {
    > .search-input {
      margin: 30px 24px 12px;
      > .selected-contacts {
        margin-bottom: 12px;
        .selected-contact-pill {
          margin-bottom: 6px;
        }
      }
    }
    .contacts-list {
      .contact-card {
        &:nth-child(1) {
          .contact-card__name {
            border: none;
            > button {
              display: none !important;
            }
          }
        }
      }
    }
    .create-group-section {
      bottom: 0;
      right: 0;
      left: 0;
      height: 106px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: rgb(237, 237, 237);
      background-color: #eee;
    }
    .no-contacts {
      text-align: center;
      padding: 72px 0;
    }
  }
}

.create-conversation.hide {
  transform: translate(-100%);
}
