.selected-contact-pill {
  height: 26px;
  display: inline-flex;
  align-items: center;
  background-color: #eee;
  border-radius: 50px;
  font-size: 0.85rem;
  padding: 0 5px 0 0;
  &__image {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background-color: #ccc;
    border-radius: 50%;
  }
  &__name {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  &__remove {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &:hover {
      background-color: rgb(240, 240, 240);
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
    }
  }
}
